<template>
  <v-container fluid>
    <v-row>
      <v-breadcrumbs :items="breadCrumbsItems" large>
        <template v-slot:divider>
          <v-icon size="11">mdi-greater-than</v-icon>
        </template>
      </v-breadcrumbs>
    </v-row>

    <v-layout wrap>
      <v-col cols="12" md="3">
        <RetailerMenu></RetailerMenu>
      </v-col>

      <v-col cols="12" md="9" class="mt-2">
        <v-row class="rowBg">
          <v-col cols="12">
            <h5>
              <v-icon size="25" color="#2776ED" class="mr-4"
                >mdi-information</v-icon
              >
              Remind other retailers to shop now!!
            </h5>
          </v-col>
        </v-row>

        <v-row wrap class="mt-10 mb-10">
          <v-col cols="12" class="rowBg">
            <v-layout class="paddingExtra">
              <v-col
                cols="12"
                class="fill-height earningStyle"
                align="center"
                justify="center"
              >
                <p>Total Earnings</p>
                <h1>US $0.00</h1>
              </v-col>
            </v-layout>
            <v-layout>
              <v-col
                cols="12"
                md="8"
                offset-md="2"
                align="center"
                justify="center"
              >
                <h5>Share and give us $20.00, you'll get $2.00</h5>
                <p>
                  <v-img
                    alt="Referal logo"
                    :src="'img/referalImg.png'"
                    transition="scale-transition"
                    width="250px"
                  />
                </p>
                <h6>
                  Share $20.00 coupon with other retailers & get $2.00 once they
                  make an order
                </h6>
                <v-layout class="mt-5">
                  <v-col cols="6" offset="3" class="codeStyle">
                    <span class="mt-2" style="font-size:30px;">
                      <b>{{ referralCode }} </b></span
                    >
                  </v-col>
                </v-layout>

                <v-layout class="mt-5">
                  <v-col cols="10" offset="1">
                    <v-btn align="center" class="buttonStyle">
                      Invite Friends
                    </v-btn>
                  </v-col>
                </v-layout>
              </v-col>
            </v-layout>
          </v-col>
        </v-row>
      </v-col>
    </v-layout>
  </v-container>
</template>

<script>
import RetailerMenu from "../others/RetailerMenu.vue";

export default {
  name: "Referals",

  components: {
    RetailerMenu,
  },

  data: () => ({
    referralCode: "",
    breadCrumbsItems: [
      {
        text: "Home",
        disabled: false,
        href: "/#/overview/retailer_dashboard/home",
      },
      {
        text: "Invite friends",
        disabled: true,
        href: "/",
      },
    ],
    userDetail: {},
    systemSetting: {
      systemCurrency: "₦",
    },
  }),
  mounted() {
    this.userDetail = this.$auth.getAuthenticatedUser();
    this.referralCode = this.$auth.getAuthenticatedUser().data.data.referralCode;
  },
  methods: {},
};
</script>

<style scoped>
.rowBg {
  background-color: #f2f2f2;
  opacity: 74%;
  border-radius: 10px;
  padding: 0px 0px 0px 10px;
}

.paddingExtra {
  padding: 40px 100px 10px 100px;
}

.earningStyle {
  border: 1px solid #c4c4c4;
  border-radius: 10px;
}

.iconStyle {
  font-size: 40px;
  color: #dd6464;
  transform: rotate(180deg);
}

.buttonStyle {
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 10px;
  color: #ffffff !important;
  font-size: 16px;
  padding-left: 25px !important;
  padding-right: 25px !important;
  padding-top: 40px;
  padding-bottom: 40px;
}

.messageRow {
  background-color: #ffffff;
  padding: 10px 10px 10px 10px;
  border: 1px outset #c2c2c2 !important;
}

.codeStyle {
  border: 1px solid #c2c2c2;
  border-radius: 10px;
}

.titleStyleColor {
  width: 411px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  background: -webkit-linear-gradient(#72bd61, #111212);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.titleStyleColorSm {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  background: -webkit-linear-gradient(#72bd61, #111212);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
